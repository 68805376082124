@import "../../../assets/scss/colors.scss";
@import "../../../assets/scss/general.scss";
@import "../../../assets/scss/break-points.scss";

.rff-dashboard-right-wrapper {
	padding-top: 9px !important;

	@include bp(sm-max) {
		padding-top: 0 !important;
		overflow: inherit;
	}
}

.navigation-top-place {
	display: flex;
	align-items: flex-end;
	justify-content: space-between;
	padding: 14px 0px;
	position: fixed;
	top: 0;
	background-color: #f7f7f7;
	width: 100%;
	z-index: 5;

	@include bp(lg-max) {
		padding: 10px 0px;
	}

	@include bp(sm-max) {
		position: relative;
		padding: 0;

		.right-header-menu {
			position: fixed;
			left: 0;
			right: 0;
			padding: 10px 16px;
			top: 0;
			background-color: #faf6ee;
			z-index: 1;
		}
	}

	.proptech-count {
		@include bp(md-max) {
			position: absolute;
			top: 52px;
			left: 16px;
		}

		@include bp(sm-max) {
			position: absolute;
			top: 0;
			left: 0;
		}
	}

	.right-nav-place {
		@include bp(md-max) {
			max-width: 100%;
			flex: 0 0 100%;
		}
	}
}

.dashboard-wrapper {
	@include bp(sm-max) {
		padding-top: 0px !important;
	}
}

.dashboard-navigation-place {
	background-color: #faf6ee;
	width: 100%;
	z-index: 1;
	position: relative;
	max-width: 100%;

	@include bp(lg-max) {
		max-width: 100%;
		left: 0;
	}
}

.proptech-card {
	background: $white;
	padding: 32px;
	margin: 0 0 24px;
	width: 100%;
	box-shadow: 0px 8px 32px rgba(17, 29, 67, 0.04);
	border-radius: 24px;
	position: relative;
	flex-wrap: wrap;

	@include bp(lg-max) {
		padding: 20px;
	}

	@include bp(sm-max) {
		padding: 15px 15px 40px 15px;
	}

	.btn-wrapper {
		margin-top: 18px;
		display: block;
		padding: 0;
		flex: 0 0 100%;
		max-width: 100%;
		position: absolute;
		bottom: 35px;
		left: 30px;

		@include bp(sm-max) {
			margin-top: 0;
			position: absolute;
			left: 20px;
			bottom: 0;
		}

		.icon-btn {
			@include bp(sm-max) {
				padding-left: 0;
			}
		}
	}

	p {
		font-weight: 400;
		font-size: 14px;
		line-height: 1.5;
		color: $primary;
		opacity: 0.6;
		font-family: "Poppins";
		padding: 15px 0;
		max-width: 510px;
		overflow: hidden;
		word-wrap: break-word;

		@include bp(desk-min) {
			max-width: 95%;
		}
	}

	.left_col-place {
		max-width: calc(100% - 460px);
		flex: 0 0 calc(100% - 460px);
		padding-bottom: 60px;

		@include bp(xlg-max) {
			max-width: calc(100% - 320px);
			flex: 0 0 calc(100% - 320px);
		}

		@include bp(lg-max) {
			max-width: calc(100% - 300px);
			flex: 0 0 calc(100% - 300px);
		}

		@include bp(sm-max) {
			max-width: 100%;
			flex: 0 0 100%;
			padding-bottom: 0;

			.nav-top {
				h2 {
					padding-right: 130px;

					@include bp(xsm-max) {
						padding-right: 0;
					}
				}
			}
		}
	}

	.right_col-place {
		max-width: 460px;
		flex: 0 0 460px;
		padding: 20px 20px 0;
		max-height: 300px;
		margin-top: 20px;
		@include bp(xlg-max) {
			max-width: 320px;
			flex: 0 0 320px;
			max-height: 320px;
			padding: 20px 10px 0;
		}

		@include bp(lg-max) {
			max-width: 300px;
			flex: 0 0 300px;
			max-height: 300px;
			padding: 20px 10px 0;
		}

		@include bp(sm-max) {
			padding: 20px 0;
			max-width: 100%;
			flex: 0 0 100%;
		}

		@include bp(xsm-max) {
			margin-left: -10px;
		}
	}
}

.nav-top {
	align-items: flex-start;

	@include bp(xsm-max) {
		flex-wrap: wrap;
	}

	.image-wrapper {
		border: 1px solid $gray;
		border-radius: 12px;
		display: inline-block;
		overflow: hidden;
		margin-right: 24px;
		height: 100px;
		width: 100px;

		@include bp(xsm-max) {
			margin: 0 0 10px;
		}

		img,
		svg {
			height: 100%;
			object-fit: cover;
			border-radius: 12px;
			width: 100%;
		}
	}

	.card-info {
		max-width: calc(100% - 125px);
		flex: 0 0 calc(100% - 125px);

		@include bp(xsm-max) {
			max-width: 100%;
			flex: 0 0 100%;
		}
	}

	h2 {
		font-weight: 600;
		font-size: 24px;
		line-height: 1.5;
		padding-bottom: 10px;
		color: $primary;
		font-family: "Poppins";

		@include bp(sm-max) {
			font-size: 20px;
		}
	}

	.listing-item-place {
		width: 100%;
		display: flex;
		align-items: center;
		padding-bottom: 5px;
		flex-wrap: wrap;

		li {
			display: inline-flex;
			font-weight: 600;
			font-size: 12px;
			line-height: 18px;
			color: $primary;
			font-family: "Poppins";
			margin-right: 25px;
			align-items: center;
			margin-bottom: 5px;

			&:last-child {
				margin-right: 0;
			}

			img {
				margin-right: 8px;
			}
		}

		&.badge-item {
			li {
				margin-right: 8px;
				background: $blue-1;
				border-radius: 50px;
				padding: 7px 8px;
				color: $blue;
				height: 26px;
			}
		}
	}
}

.bottom-menu-card-place {
	@include bp(xsm-max) {
		flex-wrap: wrap;
	}

	h2 {
		font-weight: 600;
		font-size: 12px;
		line-height: 1.5;
		color: $primary;
		font-family: "Poppins";
		padding-bottom: 8px;
		opacity: 1;
		letter-spacing: 0.1em;
	}

	.menu-item {
		display: flex;
		height: 40px;

		li {
			border: 1px solid $white_gray;
			border-radius: 54px;
			padding: 4px;
			font-weight: 600;
			font-size: 12px;
			line-height: 14px;
			font-family: "Poppins";
			max-width: 130px;
			min-width: 115px;
			word-break: break-word;
			color: $primary;
			opacity: 1;
			display: inline-flex;
			align-items: center;
			margin-right: 15px;
			overflow: hidden;
			text-overflow: ellipsis;

			img {
				margin-right: 4px;
				width: 32px;
				min-width: 32px;
			}
		}
	}

	.key-customers {
		.menu-item {
			li {
				justify-content: center;
				cursor: pointer;
				min-width: 70px !important;
				height: 40px;
				max-width: 100px !important;
				width: 100px;

				img {
					margin: 0 !important;
					border-radius: 54px;
					width: 100%;
					height: 100%;
					object-fit: cover;
				}
			}
		}
	}
}

.icon-btn {
	display: inline-flex;
	align-items: center;
	color: $primary;
	font-weight: 600;
	font-size: 14px;
	line-height: 18px;
	font-family: "Poppins";
	padding: 15px 20px;

	&:hover {
		opacity: 0.8;
	}

	img {
		margin-left: 17px;
	}

	&.left-arrow {
		img {
			margin-left: 0;
			margin-right: 17px;
			transform: rotate(180deg);
			-webkit-transform: rotate(180deg);
			-o-transform: rotate(180deg);
			-ms-transform: rotate(180deg);
		}
	}
}

.icon-wrapper {
	position: absolute;
	top: 24px;
	right: 24px;
	display: flex;
	align-items: center;
	justify-content: center;

	button {
		margin: 0 2px;
	}
}

// foryou tab
.top-fit {
	align-items: center;
	background-color: #fff5cb;
	padding: 10px 32px;
	border-top-left-radius: 24px;
	border-top-right-radius: 24px;
	min-height: 67px;

	@include bp(lg-max) {
		padding: 10px 20px;
	}

	@include bp(sm-max) {
		padding: 10px 15px;
		flex-wrap: wrap;
	}

	.top-fit-left {
		max-width: calc(100% - 160px);
		flex: 0 0 calc(100% - 160px);
		padding-right: 20px;
		flex-wrap: wrap;

		@include bp(sm-max) {
			max-width: 100%;
			flex: 0 0 100%;
			padding: 0;
			order: 2;
			margin-top: -27px;

			@include bp(xxsm-max) {
				margin-top: 10px;
			}

			> span {
				padding-right: 150px;
				padding-bottom: 10px;
				max-width: 100%;
				flex: 0 0 100%;

				@include bp(xxsm-max) {
					padding-right: 0;
				}
			}
		}
	}

	.top-fit-right {
		max-width: 160px;
		flex: 0 0 160px;
		text-align: right;

		@include bp(sm-max) {
			max-width: 100%;
			flex: 0 0 100%;
			order: 1;
		}

		@include bp(xxsm-max) {
			text-align: left;
		}

		span {
			padding-right: 0;
			font-size: 12px;
		}
	}

	span {
		font-family: "Poppins";
		font-style: normal;
		font-weight: 400;
		font-size: 14px;
		line-height: 18px;
		color: $primary;
		padding-right: 24px;
	}

	.list-name {
		display: inline-flex;
		flex-wrap: wrap;
		padding: 5px 0;
		margin-bottom: -10px;

		.badge {
			padding: 5px 16px !important;
			margin-right: 10px;
			display: inline-flex !important;
			align-items: center;
			margin-bottom: 10px;

			em {
				display: inline-block;
				line-height: 0;
				margin-right: 5px;
			}
		}
	}
}

.for-you-card {
	.proptech-card {
		border-top-left-radius: 0;
		border-top-right-radius: 0;
	}
}

// watchlist-card
.watchlist-card {
	.watchlist-data {
		display: flex;
		align-items: center;
		justify-content: space-between;
		background: #fff5cb;
		border-radius: 16px;
		padding: 12px 24px;
		margin-bottom: 16px;

		@include bp(lg-max) {
			padding: 15px;
		}

		@include bp(sm-max) {
			flex-wrap: wrap;
		}

		.left {
			display: inline-flex;
			font-family: "Poppins";
			font-style: normal;
			font-weight: 600;
			font-size: 16px;
			line-height: 18px;
			color: #0f2e29;
			align-items: center;

			@include bp(sm-max) {
				max-width: 100%;
				flex: 0 0 100%;
			}

			span {
				font-weight: 400;
				font-size: 14px;
				line-height: 18px;
				margin-left: 6px;

				strong {
					font-weight: 600;
					font-size: 16px;
				}
			}

			img {
				margin-right: 18px;
			}
		}

		.right {
			@include bp(sm-max) {
				max-width: 100%;
				flex: 0 0 100%;
				padding: 20px 0 0;
			}

			> span {
				display: inline-flex;
				font-family: "Poppins";
				font-style: normal;
				font-weight: 400;
				font-size: 14px;
				line-height: 18px;
				padding-right: 16px;
				color: #0f2e29;

				@include bp(xsm-max) {
					max-width: 100%;
					flex: 0 0 100%;
					padding: 0 0 15px;
					display: flex;
					padding-right: 0;
				}
			}

			.btn {
				height: 32px;
				font-size: 14px;

				&.fill-yellow {
					background-color: $yellow_1;
					color: $primary;
				}
			}
		}
	}
}

// dropdown
#dropdown-round-select {
	.MuiPaper-root {
		filter: none !important;
		border-radius: 8px;
	}

	ul {
		box-shadow: 0px 12px 32px rgba(17, 29, 67, 0.12);
		max-height: calc(100vh - 140px);
		overflow: auto;
		border-radius: 8px;

		@include bp(xsm-max) {
			max-height: calc(100vh - 180px);
		}

		@include bp(xxsm-max) {
			max-height: calc(100vh - 200px);
		}
	}
}

#dropdown-region-select {
	.MuiPaper-root {
		filter: none !important;
		border-radius: 8px;
	}

	ul {
		box-shadow: 0px 12px 32px rgba(17, 29, 67, 0.12);
		border-radius: 8px;
		max-height: calc(100vh - 140px);
		overflow: auto;

		@include bp(xsm-max) {
			max-height: calc(100vh - 160px);
		}
	}
}

#dropdown-sort-by-select {
	.MuiPaper-root {
		filter: none !important;
		border-radius: 8px;
	}

	ul {
		box-shadow: 0px 12px 32px rgba(17, 29, 67, 0.12);
		border-radius: 8px;
		max-height: calc(100vh - 140px);
		overflow: auto;

		@include bp(xsm-max) {
			max-height: calc(100vh - 160px);
		}
	}
}

.range_dropdown_menu {
	.MuiPaper-root {
		filter: none !important;
		border-radius: 8px;
	}

	ul {
		box-shadow: 0px 12px 32px rgba(17, 29, 67, 0.12);
		max-height: calc(100vh - 140px);
		overflow: auto;
		border-radius: 8px;

		@include bp(xsm-max) {
			max-height: calc(100vh - 160px);
		}
	}
}
