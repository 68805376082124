@import '../../../../assets/scss/colors.scss';

.number-calculation {
  .title-number {
    text-align: right;
  }

  span {
    font-family: 'Poppins';
    font-style: normal;
    color: $primary;
    font-weight: 600;
    font-size: 12px;
    line-height: 12px;
    display: inline-block;
    text-align: center;
    min-width: 84px;

    &.item-square {
      border-radius: 4px;
      padding: 8px 18px;
      border: 1px solid;

      &.gray-bg {
        border-color: $gray_7;
      }

      &.red-bg {
        border-color: $secondary;
        background-color: $gray_3;
      }

      &.blue-bg {
        border-color: $blue-1;
        background-color: $blue-1;
      }
    }
  }

  ul {
    li {
      display: flex;
      padding: 15px 0;

      div {
        text-align: center;

        &:first-child {
          text-align: left;
          width: 40%;

          span {
            text-align: left;
          }
        }

        &:nth-child(2) {
          width: 30%;
        }

        &:nth-child(3) {
          width: 30%;
        }
      }
    }
  }
}