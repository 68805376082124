//Break points
@mixin bp($point) {
    /* max-width */
    $desk-min: "(min-width:1500px)";
    $lg-desk-max: "(max-width:1439px)";
    $desk-max: "(max-width:1366px)";
    $xlg-max: "(max-width: 1199px)";
    $lg-max: "(max-width: 1023px)";
    $md-max: "(max-width: 991px)";
    $sm-max: "(max-width: 767px)";
    $xsm-max: "(max-width: 575px)";
    $xxsm-max: "(max-width: 425px)";
    $xxxsm-max: "(max-width: 375px)";

    @if $point==desk-min {
        @media #{$desk-min} {
            @content;
        }
    }

    @else if $point==lg-desk-max {
        @media #{$lg-desk-max} {
            @content;
        }
    }

    @else if $point==desk-max {
        @media #{$desk-max} {
            @content;
        }
    }

    @else if $point==xlg-max {
        @media #{$xlg-max} {
            @content;
        }
    }

    @else if $point==lg-max {
        @media #{$lg-max} {
            @content;
        }
    }

    @else if $point==md-max {
        @media #{$md-max} {
            @content;
        }
    }

    @else if $point==sm-max {
        @media #{$sm-max} {
            @content;
        }
    }

    @else if $point==xsm-max {
        @media #{$xsm-max} {
            @content;
        }
    }

    @else if $point==xxsm-max {
        @media #{$xxsm-max} {
            @content;
        }
    }

    @else if $point==xxxsm-max {
        @media #{$xxxsm-max} {
            @content;
        }
    }

}