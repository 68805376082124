@import '../../../assets/scss/colors.scss';
@import '../../../assets/scss/break-points.scss';

.custom-select {
  max-width: 180px;

  .MuiSelect-select {
    padding: 5px 16px;
    color: $primary;
    font-family: 'Poppins';
    font-weight: 600;
    font-style: normal;
    font-size: 14px;
    padding-right: 38px !important;

    @include bp(md-max) {
      padding-right: 30px !important;
    }

    em {
      color: $primary;
      font-family: 'Poppins';
      font-weight: 600;
      font-size: 14px;
      line-height: 1;
      font-style: normal;
    }
  }

  &.Mui-focused {
    border-radius: 8px;
    .MuiSelect-select {
      &[aria-expanded='true'] {
        background: $gray;
      }
    }
  }

  

  .MuiOutlinedInput-notchedOutline {
    border: none;
  }

  .MuiSvgIcon-root {
    color: $primary;
  }
}

.btn-wrapper {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  padding: 8px 24px;

  .MuiButton-root.btn {
    height: 32px;
    min-width: 82px;
    font-size: 14px;
    margin-right: 16px;

    &:last-child {
      margin-right: 0;
    }
  }
}

.ptselect-form {
  .MuiFormControl-root {
    flex-direction: row;
    align-items: center;
  }
}