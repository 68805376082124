@import '../../../../assets/scss/colors.scss';
@import '../../../../assets/scss/break-points.scss';

.company-profile-tab-place {
  .watchlist-card {
    width: 100%;

    .watchlist-data {
      width: 100%;

      @include bp(lg-max) {
        padding: 15px;
      }

      @include bp(sm-max) {
        flex-wrap: wrap;
      }

      .left {
        svg {
          margin-right: 16px;
          width: 16px;
          min-width: 16px;
        }

        @include bp(sm-max) {
          max-width: 100%;
          flex: 0 0 100%;
        }
      }

      .right {
        padding-left: 20px;

        @include bp(sm-max) {
          max-width: 100%;
          flex: 0 0 100%;
          padding: 20px 0 0;
        }

        .btn {
          min-width: 190px;
        }
      }
    }
  }
}

.protech-detail-inner-place {
  .tab-panels-place {
    .tabpanel-wrapper-place {
      h2 {
        .number-txt {
          color: $blue-2;
        }

        &.left-bordered-text {
          margin: 20px 0 4px;
          font-size: 24px;
          border-left: 4px solid;
          padding: 4px 16px;

          @include bp(sm-max) {
            font-size: 20px;
          }

          &.orange-border {
            border-color: $secondary;
          }

          &.blue-border {
            border-color: $blue;
          }
        }
      }

      .blue-theme {
        h2 {
          .number-txt {
            color: $blue;
          }
        }
      }
    }
  }
}

.modal-design-1 {
  display: flex;
  flex-wrap: wrap;

  @include bp(xsm-max) {
    &+.modal-design-1 {
      margin-top: 20px;
    }
  }

  .col-6 {
    flex: 0 0 50%;
    max-width: 50%;

    @include bp(xsm-max) {
      max-width: 100%;
      flex: 0 0 100%;

      &+.col-6 {
        margin-top: 25px;
      }
    }

    span {
      font-weight: 600;
      font-size: 12px;
      line-height: 1.5;
      color: $primary;
      opacity: 0.6;
      display: block;
      padding-bottom: 15px;
      text-transform: uppercase;
      font-family: 'Poppins';
    }

    .detail-sectors {
      @include bp(xsm-max) {
        padding-bottom: 0;
      }

      img {
        width: 40px;
      }
    }

    .growth-info {
      .growth-item {
        flex: 0 0 100%;
        max-width: 100%;
        padding: 0 12px 16px 30px;

        @include bp(sm-max) {
          padding-bottom: 0;
        }

        @include bp(xsm-max) {
          padding-bottom: 0;
          margin-bottom: 12px;
          padding-left: 15px;
        }

        img {
          width: 40px;
        }
        &:after {
          position: absolute;
          content: "";
          background-image: url(../../../../assets/Icons/ic-left-arrow-blue.svg);
          width: 7px;
          height: 13px;
          background-size: 100%;
          background-repeat: no-repeat;
          top: 1px;
          left: 10px;
  
          @include bp(xsm-max) {
            left: 0;
          }
        }

        h3 {
          line-height: 18px;
        }
      }
    }
  }
}
.market-growth-1 {
  border: 1px solid $gray_9;
  border-radius: 24px;
  margin: 20px 0;
  padding: 24px;
  width: 100%;

  .value-prop {
    width: 40px;
    height: 40px;
    @include bp(desk-min) {
      width: 50px !important;
      min-width: 50px !important;
      height: 50px !important;
    }
  }

  @include bp(lg-max) {
    padding: 20px 16px;
  }

  h2 {
    padding-bottom: 12px !important;
  }

  .growth-info {
    display: flex;
    flex-wrap: wrap;

    @include bp(sm-max) {
      margin-bottom: -20px;
    }

    &.has-para {
      .growth-item {
        &:after {
          top: 4px;
        }
      }
    }

    .growth-item {
      flex: 0 0 33.33%;
      max-width: 33.33%;
      padding-left: 30px;
      position: relative;

      &.business-modal {
        p {
          padding-bottom: 0px !important;
        }
      }

      @include bp(sm-max) {
        max-width: 50%;
        flex: 0 0 50%;
        margin-bottom: 20px;
      }

      @include bp(xsm-max) {
        max-width: 100%;
        flex: 0 0 100%;
        padding-left: 15px;
      }

      img {
        margin-bottom: 12px;
        width: 40px;
      }

      @include bp(desk-min) {
        font-size: 12px;

        img {
          width: 50px !important;
          min-width: 50px !important;
        }
      }

      &:after {
        position: absolute;
        content: "";
        background-image: url(../../../../assets/Icons/ic-left-arrow-blue.svg);
        width: 7px;
        height: 13px;
        background-size: 100%;
        background-repeat: no-repeat;
        top: 1px;
        left: 10px;

        @include bp(xsm-max) {
          left: 0;
        }
      }

      p {
        font-size: 12px !important;
        padding: 0;
      }

      h3 {
        font-family: 'Poppins';
        color: $primary;
        padding-bottom: 8px;
        font-weight: 600;
        font-size: 14px;
        line-height: 18px;
      }
    }
  }

  .growth-info {
    &.none-mb-sm {
      @include bp(sm-max) {
        margin-bottom: 0px !important;
      }
    }
  }

  &.information-growth {
    .growth-info {
      padding-top: 8px;

      .growth-item {
        padding: 0 32px 0 0;

        &:after {
          display: none;
        }
      }
    }
  }
}

.key-metrics-1 {

  &.market-growth-1 {
    border: none;
    border-radius: 24px;
    margin: 20px 0;
    padding: 24px;
    width: 100%;
  
    &.has-numbers {
      .growth-info {
        .growth-item {
          &::after {
            @include bp(xsm-max) {
              left: 0;
              top: 0px;
            }
          }
        }
      }
    }

    .growth-info {
      counter-reset: my-sec-counter;

      .growth-item {
        flex: 0 0 100%;
        max-width: 100%;
        padding: 0 12px 16px 30px;

        @include bp(xsm-max) {
          padding-bottom: 0;
          padding-left: 15px;
          margin-bottom: 20px;
        }

        &:after {
          counter-increment: my-sec-counter;
          content: counter(my-sec-counter) ". ";
          background-image: none;
          width: auto;
          height: auto;
          color: $blue-2;
          font-weight: 600;
          font-size: 14px;
          line-height: 18px;
          top: 0px;

          @include bp(xsm-max) {
            left: 0;
            top: 0px;
          }
        }
      }
    }
  }
}

.metrics-wrapper {
  &.has-three-col {
    span {
      font-weight: 400;
      font-size: 12px;
      line-height: 1.5;
      display: block;
      color: #70778E;
      padding-bottom: 4px;
      opacity: 1;
      text-transform: none
    }

    .metrics-item {
      max-width: 30%;
      flex: 0 0 30%;
      word-break: break-all;
      // align-self: flex-start;

      @include bp(xlg-max) {
        max-width: 50%;
        flex: 0 0 50%;
      }

      @include bp(xsm-max) {
        max-width: 100%;
        flex: 0 0 100%;
      }
    }
  }

  &.has-five-col {
    .metrics-item {
      padding: 0 20px;
      max-width: 20%;
      flex: 0 0 20%;
      margin-bottom: 20px;
      word-break: break-all;

      // align-self: flex-start;
      @include bp(lg-max) {
        max-width: 50%;
        flex: 0 0 50%;
      }

      @include bp(xsm-max) {
        max-width: 100%;
        flex: 0 0 100%;
      }
    }
  }
}

.key-customer-logo {
  li {
    img {
      margin: 0 !important;
      border-radius: 12px;
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }
}
.st-20,
.st-330 {
  display: none;
}
@media print {
  body{
    -webkit-print-color-adjust:exact !important;
    print-color-adjust:exact !important;
  }
  .proptech-detail-wrapper-place {
    width: 1024px !important;
  }
  .no-print {
    display: none !important;
  }
  .printable-content {
    display: block !important;
  }
  @page {
    size: 210mm 297mm;
    box-shadow: 0;
    margin: 15px 0;
  }
  #download-summary-btn, #back-to-top, #see-more-btn, .icon-wrapper {
    display: none !important;
  }
  .header-1 {
    padding-top: 24px !important;
  }
  #place-header-1 {
    display: none;
  }
  #budget-section {
    display: flex;
    flex-direction: column;
  }
  #place-dashboard {
    position: absolute;
  }
  .project-tab-place .market-overview-place  .item-market {
    width: 100% !important;
    flex: 0 0 100% !important;
    max-width: 100% !important;
  }
  #project-countries {
    max-width: 100% !important;
    height: auto !important;
    overflow: hidden;
  }
  #timeline-container {
    overflow: hidden !important;
  }
  .overview-project {
    width: 100% !important;
    flex: 0 0 100% !important;
    max-width: 100% !important;
  }
  .market-growth {
    break-inside: avoid;
  }
  .project-content {
    page-break-inside: auto;
    page-break-after: always;
  }
  .raise-detail-wrapper-place {
    page-break-before: always;
  }
  .market-growth-insights, .market-growth-1 {
    break-inside: avoid;
    .growth-info {
      break-inside: avoid;
      .growth-item {
        flex: 0 0 33.33%;
        max-width: 33.33%;
      }
    }
  }
  .modal-design-1 {
    break-inside: avoid;
  }
  .blue-theme {
    page-break-inside: avoid;
  }
  .print-setting-2 {
    page-break-before: always;
    height: 30px;
  }
  .print-setting-1 {
    break-inside: avoid;
  }
  .print-setting-3 {
    page-break-before: always;
    height: 30px;
  }
  .proptech-detail-wrapper-place .navigation-top-place {
    display: none;
  }
  .proptech-detail-wrapper-place .top-menu-place {
    padding-top: 15px !important;
    margin-bottom: 24px !important;
    padding-bottom: 12px !important;
    margin-left: 20px !important;
  }
  .protech-detail-inner-place .tab-panels-place .tabpanel-wrapper-place {
    max-width: 100% !important;
  }
  .protech-detail-inner-place {
    margin-top: -20px !important;
  }
}