@import "../../../../assets/scss/colors.scss";
@import "../../../../assets/scss/break-points.scss";

.design-review-tab-1 {
	.watchlist-card {
		max-width: 100%;
		flex: 0 0 100%;

		@include bp(xlg-max) {
			padding: 0 12px;
		}

		.watchlist-data {
			background-color: $gray_8;
			margin-bottom: 25px;

			@include bp(lg-max) {
				padding: 15px;
				margin-bottom: 15px;
			}

			@include bp(sm-max) {
				flex-wrap: wrap;
			}

			.left {
				align-items: center;
				font-size: 14px;
				font-weight: 400;
				line-height: 1.4;

				@include bp(sm-max) {
					max-width: 100%;
					flex: 0 0 100%;
				}

				@include bp(xsm-max) {
					flex-wrap: wrap;
				}
			}

			em {
				min-width: 145px;
				margin-right: 10px;

				@include bp(xsm-max) {
					max-width: 100%;
					flex: 0 0 100%;
					margin: 0 0 12px;
				}

				img {
					margin-right: 0;
				}
			}

			.right {
				@include bp(sm-max) {
					max-width: 100%;
					flex: 0 0 100%;
					padding: 5px 0 0;
					margin-left: -14px;
				}

				.btn {
					background-color: transparent;
					color: $primary;
					font-size: 14px;
					font-weight: 600;
					padding-right: 0;
					min-width: 137px;
				}
			}
		}
	}

	.market-growth {
		margin: 0;
		padding: 0;
		border: 0;
		background-color: transparent;
	}

	.left_col-place {
		.market-growth {
			.growth-info {
				margin-bottom: 0;

				@include bp(sm-max) {
					margin-bottom: 25px;
				}

				.growth-item {
					max-width: 100%;
					flex: 0 0 100%;
					margin-bottom: 16px;
					word-break: break-word;
					position: relative;
					padding-left: 30px;

					p {
						font-size: 14px;
						word-break: break-word;
						padding: 0;
						@include bp(sm-max) {
							font-size: 14px;
						}
					}

					&:after {
						position: absolute;
						content: "";
						background-image: url(../../../../assets/Icons/ic-left-arrow-blue.svg);
						width: 7px;
						height: 13px;
						background-size: 100%;
						background-repeat: no-repeat;
						top: 1px;
						left: 10px;

						@include bp(xsm-max) {
							left: 0;
						}
					}

					&:last-child {
						margin-bottom: 0;
					}

					&::after {
						left: 7px;
						top: 4px;

						@include bp(xsm-max) {
							left: 0;
						}
					}
				}
			}
		}
	}

	.design-key-points {
		padding: 0 12px;
		max-width: 100%;
		flex: 0 0 100%;
		margin-top: 40px;

		@include bp(xlg-max) {
			padding: 0 12px;
		}

		@include bp(md-max) {
			margin-bottom: 25px;
		}

		@include bp(sm-max) {
			margin-bottom: 0;
		}

		.growth-info {
			margin: 0 -12px -25px;
			display: flex;
			flex-wrap: wrap;
			@include bp(sm-max) {
				margin: 0 -10px -20px;
			}

			.growth-item-wrap {
				max-width: 50%;
				flex: 0 0 50%;
				padding: 0 12px;
				margin-bottom: 25px;

				@include bp(sm-max) {
					padding: 0 10px;
					margin-bottom: 20px;
				}

				@include bp(xsm-max) {
					max-width: 100%;
					flex: 0 0 100%;
				}

				.growth-item {
					border: 1px solid $gray;
					border-radius: 24px;
					padding: 23px;
					height: 100%;
					position: relative;

					@include bp(lg-max) {
						padding: 20px 15px;
					}

					h3 {
						font-size: 16px;
						padding-left: 14px;
						padding-bottom: 17px;
						color: #0f2e29;
						font-weight: 600;
						line-height: 18px;
						padding-bottom: 8px;
					}

					&::after {
						font-size: 16px;
						top: 23px;
						left: 19px;
						position: absolute;

						@include bp(lg-max) {
							font-size: 16px;
							top: 20px;
							left: 10px;
						}
					}

					p {
						font-size: 14px;
						word-break: break-all;

						@include bp(sm-max) {
							font-size: 12px;
						}

						&.small-text {
							font-size: 12px !important;
						}
					}
				}
			}
		}
	}
}

.rating-process-wrapper {
	margin: 20px 0 25px;

	@include bp(sm-max) {
		margin: 15px 0 20px;
	}

	span {
		font-size: 40px;
		font-weight: 600;
		color: $primary;
		display: block;
		margin-bottom: 10px;

		@include bp(xlg-max) {
			font-size: 36px;
		}

		@include bp(sm-max) {
			font-size: 32px;
		}
	}

	ul {
		margin: 0 -3px;
		border-radius: 8px;
		display: flex;

		li {
			padding: 0 3px;
			max-width: 20%;
			flex: 0 0 20%;
			line-height: 0;

			&:first-child {
				em {
					border-radius: 8px 0 0 8px;
				}
			}

			&:last-child {
				em {
					border-radius: 0 8px 8px 0;
				}
			}

			em {
				width: 100%;
				background-color: $white_gray;
				display: inline-block;
				height: 8px;

				&.line1 {
					background-color: $red;
				}

				&.line2 {
					background-color: $orange;
				}

				&.line3 {
					background-color: $yellow_1;
				}

				&.line4 {
					background-color: $lemon_yellow;
				}

				&.line5 {
					background-color: $green;
				}
			}
		}
	}
}

.key-metrics-1 {
	&.market-growth {
		border: none;

		.growth-info {
			counter-reset: my-sec-counter;

			.growth-item {
				flex: 0 0 100%;
				max-width: 100%;
				padding: 0 12px 16px 30px;

				@include bp(xsm-max) {
					padding-bottom: 0;
					padding-left: 15px;
					margin-bottom: 20px;
				}

				&:after {
					counter-increment: my-sec-counter;
					content: counter(my-sec-counter) ". ";
					background-image: none;
					width: auto;
					height: auto;
					color: $blue-2;
					font-weight: 600;
					font-size: 14px;
					line-height: 18px;
					top: 2px;

					@include bp(xsm-max) {
						left: 0;
						top: 1px;
					}
				}
			}
		}
	}
}
