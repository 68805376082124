@import '../../../assets/scss/break-points.scss';

.welcome-modal {
  max-width: 768px !important;

  @include bp(sm-max) {
    max-width: calc(100% - 30px) !important;
  }

  .footer-btn {
    @include bp(xxsm-max) {
      flex-direction: column;

      button {
        margin-right: 0 !important;

        +button {
          margin-top: 10px;
        }
      }
    }
  }
}

.find-out-more {
  font-weight: 600;
  cursor: pointer;
}