@import '../../../assets/scss/colors.scss';
@import '../../../assets/scss/break-points.scss';

.share-modal {
  * {
    font-family: 'Poppins' !important;
  }

  .MuiBox-root {
    max-width: 550px !important;

    @include bp(sm-max) {
      padding: 32px !important;
      max-width: 75% !important;
    }
  }

  .MuiButton-root.btn.fill-gray,
  .MuiButton-root.btn.fill-green {
    height: 36px;
  }

  .fill-green {
    background-color: $green;
    color: #ffffff;
    height: 36px;

    &:hover {
      background-color: $green;
    }
  }

  .form-wrapper {
    padding-top: 0px;

    .form-group {
      margin-bottom: 29px !important;

      @include bp(sm-max) {
        margin-bottom: 16px !important;
      }

      .form-control {
        &.textarea-input {
          .MuiOutlinedInput-input {
            height: 98px !important;
          }
        }
      }
    }
  }

  .btn-wrapper-share-modal {
    display: flex;
    align-items: center;
    justify-content: flex-end;

    .disable-share-btn {
      color: $white;
      background-color: $gray_9;
    }
  }

  .review-meeting {
    justify-content: center;

    .review-btn {
      height: 64px;
      width: 64px;
      border-radius: 16px;
      margin-right: 16px;
      margin-left: 0;

      &:last-child {
        margin-right: 0;
      }
    }
  }

  .badge-wrapper {
    text-align: center;
    margin-top: 41px;

    .badge {
      padding: 8px 16px !important;
      margin: 8px 6px;
      border-color: $gray !important;

      &.green-badge {
        border-color: $green !important;
      }

      &.red-badge {
        border-color: $red !important;
      }
    }
  }
}